import { nanoid } from 'nanoid';


// HEAD DATA
export const headData = {
  title: 'Josh Colavito | Web Developer', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Welcome, my name is',
  name: 'Josh Colavito',
  subtitle: 'I\'m a web developer.',
  cta: 'About Me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpeg',
  paragraphOne: 'As a lifelong computer lover, I always knew that I would work in a technological field. My studies took me down the route of traditional I.T. operations, where I refined my love of troubleshooting.',
  paragraphTwo: 'I began writing PowerShell scripts to automate work functions and quickly found that the process of writing code was something I could get lost in for hours. Since then, I have worked diligently to increase my skillset.',
  paragraphThree: 'I have a passion for learning and coding has been an excellent target to aim at. I hope to continue developing my skillset and set aside time daily to keep myself fresh and moving forward.',
  resume: '../pdf/colavito-resume-8-21.pdf'
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'dmtool.png',
    title: 'Dungeon Master\'s Toolbox',
    info: 'This is an application that I created to assist in facilitating Dungeons and Dragons campaigns for the Dungeon Master. It features an item database, comment functionality, a Leaflet.js plugin to allow visualization of the world map, and an in-game calendar integration.',
    info2: 'This project has been the primary output of my studies. It is a full-stack application, featuring a fully-functional hosted MongoDB database that runs through an Express server. The front-end is constructed in React and utilizes Material-UI for the visual component. It is secured with authentication on the front-end and back-end utilizing Auth0.',
    url: 'https://colavitoj.github.io',
    repo: 'https://github.com/colavitoj/dungeon-masters-toolbox', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'movie-search-engine.PNG',
    title: 'Movie Database Search Engine',
    info: 'A search engine created in React, utilizing the public MovieDB api. Can search by title and retrieve a variety of information, including film posters, ratings, and synopses.  ',
    info2: '',
    url: '',
    repo: 'https://github.com/colavitoj/movie-search-engine', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'josh@colavito.org',
};

// FOOTER DATA
export const footerData = {
  networks: [

    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/josh-colavito-851a87105/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/colavitoj',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
